import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { styles } from "../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../components/analytics/elearning/ReportTableWrapper";
import { newsUpdateServices } from "../../../../../News/NewsUpdate/modules/services";
import axios from "axios";

const NewsBasedUsersReport = ({ classes, news_id, startDate, endDate }) => {
  const [userssData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("user_name");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  useEffect(() => {
    setUsersData([]);
    fetchUsersData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchUsersData = (sortBy = orderBy, sortOrder = order) => {
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      news: news_id,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    newsUpdateServices
      .getUserReport(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setUsersData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleSortRequest = (property) => {
    setUsersData([]);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchUsersData(property, isAsc ? "desc" : "asc");
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const headers = [
    { id: "user_name", label: "User Name" },
    {
      id: "news_seen_percentage",
      label: "Seen",
    },
    {
      id: "news_ack_percentage",
      label: "Acknowledged",
    },
  ];

  const data = userssData?.map((row) => ({
    _id: row._id,
    "User Name": row.user_name || "N/A",
    Seen:
      row.news_seen === 1 ? (
        <div className={classes.successBox}>Seen</div>
      ) : (
        <div className={classes.pending}>Pending</div>
      ),
    Acknowledged:
      row.news_ack === 1 ? (
        <div className={classes.successBox}>Acknowledged</div>
      ) : (
        <div className={classes.pending}>Pending</div>
      ),
  }));

  return (
    <>
      <ReportTableWrapper
        classes={classes}
        data={data}
        headers={headers}
        loading={loading}
        page={page}
        totalPages={totalPages}
        onPageChange={setPage}
        onSortRequest={handleSortRequest}
        order={order}
        orderBy={orderBy}
        handleCountPercentage={handleCountPercentage}
        checked={checked}
      />
    </>
  );
};

const ConnectWith = connect(null, null)(NewsBasedUsersReport);

export default withStyles(styles)(ConnectWith);
