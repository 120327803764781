import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import "react-loading-skeleton/dist/skeleton.css";
import { TableSortLabel, styled } from "@material-ui/core";
import TableRecordNotFound from "../../TableRecordNotFound";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: "10px",
    width: "100%",
    backgroundColor: "white",
  },
  trHead: {
    borderBottom: "1px solid #00000033",
  },
  th: {
    fontSize: "14px",
    padding: "10px",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  td: {
    fontSize: "14px",
    color: "#000000CC",
    padding: "10px",
    borderBottom: "1px solid #00000033",
  },
  skeleton: {
    borderRadius: "0.5rem",
    height: "40px",
  },
  headCell: {
    padding: "0.5rem 0rem",
    color: "#000000 !important",
    fontWeight: 700,
    textTransform: "capitalize",
    "& .MuiTableSortLabel-icon": {
      color: "black !important",
    },
  },
  lastRow: {
    "& td": {
      borderBottom: "none",
    },
  },
  tableBodyRow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const SortLabelWrapper = styled("div")(({ theme }) => ({
  "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
    {
      color: "black !important",
    },
  "& .MuiTableSortLabel-root .MuiTableSortLabel-icon": {
    color: "black !important",
  },
}));

const CustomTable = ({
  headers,
  data,
  loading,
  onRowClick,
  handleSortRequest,
  order,
  orderBy,
}) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.trHead}>
            {headers.map((header, index) => (
              <TableCell
                key={header.id}
                className={classes.th}
                onClick={() => handleSortRequest(header.id)}
              >
                <SortLabelWrapper>
                  <TableSortLabel
                    className={`${classes.headCell} ${classes.customSortLabel}`}
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : "asc"}
                  >
                    {header.label}
                  </TableSortLabel>
                </SortLabelWrapper>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, rowIndex) => (
            <TableRow
              hover
              key={rowIndex}
              onClick={onRowClick ? () => onRowClick(row) : null}
              className={`${
                rowIndex === data.length - 1 ? classes.lastRow : ""
              } ${onRowClick ? classes.tableBodyRow : ""}`}
            >
              {headers.map((header, cellIndex) => (
                <TableCell key={cellIndex} className={classes.td}>
                  {row[header.label] !== undefined ? row[header.label] : "N/A"}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {loading &&
            [...Array(5)].map((_, index) => (
              <TableRow
                key={index}
                className={index === 4 ? classes.lastRow : ""}
              >
                {headers.map((_, cellIndex) => (
                  <TableCell key={cellIndex} className={classes.td}>
                    <Skeleton className={classes.skeleton} />
                  </TableCell>
                ))}
              </TableRow>
            ))}

          {!loading && data && data.length === 0 ? (
            <TableRecordNotFound colSpan={8} label="No Data Found.." />
          ) : (
            ""
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CustomTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
};

CustomTable.defaultProps = {
  loading: false,
};

export default CustomTable;
