import React, { useState, useEffect } from "react";
import { Grid, Button, withStyles } from "@material-ui/core";
import LocationBasedRolesReport from "./LocationBasedRolesReport";
import LocationBasedUsersReport from "./LocationBasedUsersReport";
import { useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { history } from "../../../../../../history";
import { styles } from "../../../../ReportStyles";
import Breadcrumb from "../../../../../../components/analytics/Breadcrumb";

const LocationBasedReports = ({ classes }) => {
  const { lid, tab } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("startDate");
  const endDate = queryParams.get("endDate");
  const location_name = queryParams.get("location_name");

  const [activeIndex, setActiveIndex] = useState(1);

  useEffect(() => {
    if (tab === "users") {
      setActiveIndex(2);
    } else {
      setActiveIndex(1);
    }
  }, [tab]);

  const handleTabChange = (label) => {
    setActiveIndex(label.status);

    const newQueryParams = new URLSearchParams(location.search);
    if (startDate) {
      newQueryParams.set("startDate", startDate);
    }
    if (endDate) {
      newQueryParams.set("endDate", endDate);
    }
    if (location_name) {
      newQueryParams.set("location_name", location_name);
    }

    history.push({
      pathname: `/analytics/news/location/${lid}/${label.title}`,
      search: newQueryParams.toString(),
    });
  };

  const buttonsList = [
    { status: 1, title: "roles" },
    { status: 2, title: "users" },
  ];

  const breadcrumb_items = [{ name: location_name, link: "" }];

  return (
    <Grid className={classes.main}>
      <Breadcrumb
        items={breadcrumb_items}
        backUrl={"/analytics/news/location"}
      />

      <Grid
        style={{ margin: "auto", paddingTop: "30px", paddingBottom: "30px" }}
        item
        xl={6}
        md={6}
        lg={8}
        sm={8}
        xs={12}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.tabs}
        >
          {buttonsList.map((label, index) => {
            const isFirstButton = index === 0;
            const isLastButton = index === buttonsList.length - 1;

            return (
              <Grid>
                <Button
                  style={{
                    borderRadius: isFirstButton
                      ? "8px 0 0 8px"
                      : isLastButton
                      ? "0 8px 8px 0"
                      : 0,
                    color: activeIndex === label.status ? "white" : "black",
                    backgroundColor:
                      activeIndex === label.status ? "black" : "white",
                  }}
                  className={classes.tabButton}
                  onClick={() => handleTabChange(label)}
                >
                  {label.title}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      {activeIndex === 1 ? (
        <LocationBasedRolesReport
          lid={lid}
          startDate={startDate}
          endDate={endDate}
        />
      ) : (
        <LocationBasedUsersReport
          lid={lid}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </Grid>
  );
};

const ConnectWith = connect(null, null)(LocationBasedReports);

export default withStyles(styles)(ConnectWith);
