import React, { useState, useEffect, useRef } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { dashboardServices } from "../../../../../../eLearning/Dashboard/modules/services";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { styles } from "../../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../../components/analytics/elearning/ReportTableWrapper";
import { newsUpdateServices } from "../../../../../../News/NewsUpdate/modules/services";
import { invalidToken } from "../../../../../../../utilities";
import axios from "axios";

const LocationUserBasedNewsReport = ({ classes }) => {
  const { lid, uid } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("startDate");
  const endDate = queryParams.get("endDate");
  const location_name = queryParams.get("location_name");
  const user_name = queryParams.get("user_name");

  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  useEffect(() => {
    setNewsData([]);
    fetchNewsData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchNewsData = (sortBy = orderBy, sortOrder = order) => {
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      lid,
      uid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    newsUpdateServices
      .getNewsReport(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success === 2) {
          invalidToken(response?.data?.message);
        } else {
          setNewsData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const handleSortRequest = (property) => {
    setNewsData([]);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchNewsData(property, isAsc ? "desc" : "asc");
  };

  const headers = [
    {
      id: "title",
      label: "News",
    },
    {
      id: "news_seen_percentage",
      label: "Seen",
    },
    {
      id: "news_ack_percentage",
      label: "Acknowledged",
    },
  ];

  const tableData = newsData?.map((row) => ({
    News:
      row?.title?.length > 35
        ? row?.title?.substring(0, 35) + "..."
        : row?.title,
    Seen:
      row.news_seen === 1 ? (
        <div className={classes.textSuccess}>Seen</div>
      ) : (
        <div className={classes.textFail}>Pending</div>
      ),
    Acknowledged:
      row.news_ack === 1 ? (
        <div className={classes.textSuccess}>Acknowledged</div>
      ) : (
        <div className={classes.textFail}>Pending</div>
      ),
  }));

  const breadcrumb_items = [
    {
      name: location_name,
      link: `/analytics/news/location/${lid}/users?location_name=${location_name}`,
    },
    {
      name: user_name,
      link: ``,
    },
  ];

  return (
    <>
      <Grid className={classes.main}>
        <ReportTableWrapper
          classes={classes}
          data={tableData}
          headers={headers}
          loading={loading}
          page={page}
          totalPages={totalPages}
          onPageChange={setPage}
          onSortRequest={handleSortRequest}
          order={order}
          orderBy={orderBy}
          handleCountPercentage={handleCountPercentage}
          checked={checked}
          breadcrumbItems={breadcrumb_items}
        />
      </Grid>
    </>
  );
};

const ConnectWith = connect(null, null)(LocationUserBasedNewsReport);

export default withStyles(styles)(ConnectWith);
