import React from "react";
import { IconButton, Grid, withStyles, Typography } from "@material-ui/core";
import CustomTable from "./CustomTable";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { styles } from "../../../pages/analytics/ReportStyles";
import Switch from "react-switch";
import Breadcrumb from "../Breadcrumb";

const ReportTableWrapper = ({
  classes,
  title,
  data,
  headers,
  loading,
  page,
  totalPages,
  onPageChange,
  onRowClick,
  onSortRequest,
  order,
  orderBy,
  breadcrumbItems,
  checked,
  handleCountPercentage,
  backUrl,
}) => {
  return (
    <>
      {breadcrumbItems && (
        <Breadcrumb items={breadcrumbItems} backUrl={backUrl || ""} />
      )}
      <Grid className={classes.tableView}>
        <Grid container justifyContent="space-between">
          <Grid className={classes.tableHeaderLeft}>
            {title ? (
              <Typography variant="h6" className={classes.tableHeading}>
                {title}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid className={classes.tableHeaderRight}>
            <Grid className={classes.switchView}>
              <Switch
                onChange={handleCountPercentage}
                checked={checked}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#FE5E37"
                height={17}
                width={35}
              />
              <Typography className={classes.switchViewLabel}>
                {checked ? "Percentage" : "Count"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <CustomTable
          headers={headers}
          data={data}
          loading={loading}
          onRowClick={onRowClick}
          handleSortRequest={onSortRequest}
          order={order}
          orderBy={orderBy}
        />
      </Grid>
      <Grid style={{ float: "right" }}>
        <IconButton
          size="small"
          disabled={page === 1 || loading}
          onClick={() => onPageChange(page - 1)}
        >
          <ArrowCircleLeftIcon />
        </IconButton>
        <IconButton
          size="small"
          disabled={totalPages <= page || loading}
          onClick={() => onPageChange(page + 1)}
        >
          <ArrowCircleRightIcon />
        </IconButton>
      </Grid>
    </>
  );
};

export default withStyles(styles)(ReportTableWrapper);
