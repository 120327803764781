import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import { styles } from "../../../ReportStyles";
import ReportTableWrapper from "../../../../../components/analytics/elearning/ReportTableWrapper";
import { analyticsReportsServices } from "../../../modules/services";
import axios from "axios";

const AllRoleBasedUsersReport = ({ classes, rid, startDate, endDate }) => {
  const permissionsData = useSelector(
    (state) => state.settingsRoles.permissionsData
  );
  const elearningPermissionsData = permissionsData["elearning_dashboard"];
  const newsPermissionsData = permissionsData["view_news_report"];

  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("user_name");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  useEffect(() => {
    fetchUsersData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchUsersData = (sortBy = orderBy, sortOrder = order) => {
    setUsersData([]);
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      rid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    analyticsReportsServices
      .fetchAnalyticsAllUserReportsData(
        data,
        cancelTokenSourceRef.current.token
      )
      .then((response) => {
        if (response.data.success) {
          setUsersData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchUsersData(property, isAsc ? "desc" : "asc");
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const headers = [
    {
      id: "user_name",
      label: "User Name",
    },
    elearningPermissionsData && {
      id: checked ? "completed_lessons_percentage" : "completed_lessons",
      label: "eLearning",
    },
    newsPermissionsData && {
      id: checked ? "news_ack_percentage" : "news_ack",
      label: "News",
    },
    {
      id: "overall_percentage",
      label: "Overall(%)",
    },
  ].filter(Boolean);

  const tableData = usersData?.length
    ? usersData?.map((row) => ({
        _id: row._id,
        "User Name":
          row?.user_name?.length > 30
            ? row?.user_name?.substring(0, 30) + "..."
            : row?.user_name,
        eLearning: checked
          ? `${row?.completed_lessons_percentage}%`
          : `${row?.completed_lessons} / ${row?.assigned_lessons}`,
        News: checked
          ? `${row?.news_ack_percentage}%`
          : `${row?.news_ack} / ${row?.total_news}`,
        "Overall(%)": row?.overall_percentage,
      }))
    : [];

  return (
    <>
      <ReportTableWrapper
        classes={classes}
        title=""
        data={tableData}
        headers={headers}
        loading={loading}
        page={page}
        totalPages={totalPages}
        onPageChange={setPage}
        onSortRequest={handleSortRequest}
        order={order}
        orderBy={orderBy}
        handleCountPercentage={handleCountPercentage}
        checked={checked}
      />
    </>
  );
};

const ConnectWith = connect(null, null)(AllRoleBasedUsersReport);

export default withStyles(styles)(ConnectWith);
