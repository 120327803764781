import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { history } from "../../../../../../history";
import { useLocation } from "react-router-dom";
import { styles } from "../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../components/analytics/elearning/ReportTableWrapper";
import { newsUpdateServices } from "../../../../../News/NewsUpdate/modules/services";
import axios from "axios";

const RoleBasedLocationsReport = ({ classes, rid, startDate, endDate }) => {
  const [locationsData, setLocationsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  const location = useLocation();

  useEffect(() => {
    setLocationsData([]);
    fetchLocationsData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchLocationsData = (sortBy = orderBy, sortOrder = order) => {
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      rid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    newsUpdateServices
      .fetchLocationBasedReports(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setLocationsData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleSortRequest = (property) => {
    setLocationsData([]);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchLocationsData(property, isAsc ? "desc" : "asc");
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const handleRowClick = (row) => {
    const newQueryParams = new URLSearchParams(location.search);
    const role_name = newQueryParams.get("role_name");

    if (startDate) {
      newQueryParams.set("startDate", startDate);
    }
    if (endDate) {
      newQueryParams.set("endDate", endDate);
    }

    newQueryParams.set("location_name", row.location_name);
    newQueryParams.set("role_name", role_name);

    console.log("Analytics location id:", row);

    history.push({
      pathname: `/analytics/news/role/${rid}/locations/${row._id}`,
      search: newQueryParams.toString(),
    });
  };

  const headCells = [
    { id: "name", label: "Location Name" },
    { id: "assign", label: "Assignee" },
    {
      id: "total_news",
      label: "News",
    },
    { id: checked ? "news_seen_percentage" : "news_seen", label: "Seen" },
    { id: checked ? "news_ack_percentage" : "news_ack", label: "Acknowledged" },
  ];

  const data = locationsData?.map((row) => ({
    _id: row._id,
    location_name: row?.name,
    "Location Name":
      row?.name?.length > 30 ? row?.name.substring(0, 30) + "..." : row?.name,
    Assignee: row?.assign || "N/A",
    News: row?.total_news || "N/A",
    Seen: checked
      ? `${row.news_seen_percentage}%`
      : `${row.news_seen} / ${row.assign}`,

    Acknowledged: checked
      ? `${row.news_ack_percentage}%`
      : `${row.news_ack} / ${row.assign}`,
  }));

  return (
    <>
      <ReportTableWrapper
        classes={classes}
        data={data}
        headers={headCells}
        loading={loading}
        page={page}
        totalPages={totalPages}
        onPageChange={setPage}
        onRowClick={handleRowClick}
        onSortRequest={handleSortRequest}
        order={order}
        orderBy={orderBy}
        handleCountPercentage={handleCountPercentage}
        checked={checked}
      />
    </>
  );
};

const ConnectWith = connect(null, null)(RoleBasedLocationsReport);

export default withStyles(styles)(ConnectWith);
