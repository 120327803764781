import React, { useState, useEffect, useRef } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { history } from "../../../../../../../history";
import { useParams, useLocation } from "react-router-dom";
import { styles } from "../../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../../components/analytics/elearning/ReportTableWrapper";
import { newsUpdateServices } from "../../../../../../News/NewsUpdate/modules/services";
import axios from "axios";

const RoleLocationBasedUsersReport = ({ classes }) => {
  const { lid, rid } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("startDate");
  const endDate = queryParams.get("endDate");

  const location_name = queryParams.get("location_name");
  const role_name = queryParams.get("role_name");

  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("user_name");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  useEffect(() => {
    setUsersData([]);
    fetchUsersData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchUsersData = (sortBy = orderBy, sortOrder = order) => {
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      lid,
      rid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    newsUpdateServices
      .getUserReport(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setUsersData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleSortRequest = (property) => {
    setUsersData([]);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchUsersData(property, isAsc ? "desc" : "asc");
  };

  const handleRowClick = (row) => {
    const newQueryParams = new URLSearchParams(location.search);
    if (startDate) {
      newQueryParams.set("startDate", startDate);
    }
    if (endDate) {
      newQueryParams.set("endDate", endDate);
    }

    newQueryParams.set("location_name", location_name);
    newQueryParams.set("role_name", role_name);
    newQueryParams.set("user_name", row.user_name);

    console.log("Analytics location id:", row);

    history.push({
      pathname: `/analytics/news/role/${rid}/locations/${lid}/${row._id}`,
      search: newQueryParams.toString(),
    });
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const headers = [
    {
      id: "user_name",
      label: "User Name",
    },
    {
      id: "total_news",
      label: "News",
    },
    {
      id: checked ? "news_seen_percentage" : "news_seen",
      label: "Seen",
    },
    {
      id: checked ? "news_ack_percentage" : "news_ack",
      label: "Acknowledged",
    },
  ];

  const rows = usersData.map((row) => ({
    _id: row._id,
    user_name: row?.user_name,
    "User Name": row?.user_name || "N/A",
    News: row?.total_news || "N/A",
    "In Lesson Quiz": checked
      ? `${
          parseFloat(
            (
              (row?.completed_question_count / row?.question_count) *
              100
            ).toFixed(2)
          ) || 0
        }%`
      : `${row?.completed_question_count} / ${row?.question_count}`,
    Seen: checked
      ? `${row.news_seen_percentage}%`
      : `${row.news_seen} / ${row.total_news}`,
    Acknowledged: checked
      ? `${row.news_ack_percentage}%`
      : `${row.news_ack} / ${row.total_news}`,
  }));

  const breadcrumb_items = [
    {
      name: role_name,
      link: `/analytics/news/role/${rid}/locations?role_name=${role_name}`,
    },
    {
      name: location_name,
      link: "",
    },
  ];

  return (
    <>
      <Grid className={classes.main}>
        <ReportTableWrapper
          classes={classes}
          data={rows}
          headers={headers}
          loading={loading}
          page={page}
          totalPages={totalPages}
          onPageChange={setPage}
          onRowClick={handleRowClick}
          onSortRequest={handleSortRequest}
          order={order}
          orderBy={orderBy}
          handleCountPercentage={handleCountPercentage}
          checked={checked}
          breadcrumbItems={breadcrumb_items}
        />
      </Grid>
    </>
  );
};

const ConnectWith = connect(null, null)(RoleLocationBasedUsersReport);

export default withStyles(styles)(ConnectWith);
