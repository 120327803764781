import { HEADER_TOKEN, MY_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from "axios";

function fetchData(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/created`;

  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
      params: data,
    })
    .then((response) => response);
}

function fetchAssignUsers() {
  return axios
    .get(`${MoNGO_DB_API_URL}/newsupdates/roles/mongo/new`, HEADER_TOKEN)
    .then((response) => response);
}

function fetchCompanyUsers() {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/users/company`;
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}

function fetchAssignRolesUsers() {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/roles`;
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}

function fetchAssignLocationUsers() {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/locations`;
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}

function fetchlocationIdData(lid) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/newsupdates/dashboard/news/locationBased/user/${lid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
function fetchroleIdData(rid) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/newsupdates/dashboard/news/roleBased/user/${rid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addNews(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news`;
  return axios.post(url, { data }, HEADER_TOKEN).then((response) => response);
}

function editNews(data) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/newsupdates/v2/edit/news/${data._id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function pushNotification(data) {
  return axios
    .get(`${MoNGO_DB_API_URL}/newsupdates/Sent/v2/${data._id}`, HEADER_TOKEN)
    .then((response) => response);
}

function newsDashboard() {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/newsupdates/dashboard/news/locationBased`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function newsDashboardLocation(lid) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/newsupdates/dashboard/news/locationBased/user/${lid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function newsRoleDashboard() {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/newsupdates/dashboard/news/roleBased`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function SeenNews(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/seen`;
  return axios.put(url, { data }, HEADER_TOKEN).then((response) => response);
}

function deleteNews(id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchSeenUsers(id) {
  return axios
    .get(`${MoNGO_DB_API_URL}/newsupdates/seen/v2/${id}`, HEADER_TOKEN)
    .then((response) => response);
}

function fetchUsers() {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/locations`;
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}

function fetchRoles() {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/roles`;
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}
function fetchPreviewUsers(id) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/my/preview/${id}`;
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}

function fetchstatsUsers(id, data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/status/${id}`;
  return axios.post(url, { data }, HEADER_TOKEN).then((response) => response);
}

function AddComments(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/comments`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function EditComment(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/comments`;
  return axios.put(url, { data }, HEADER_TOKEN).then((response) => response);
}
function EditReply(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/comments/reply`;
  return axios.put(url, { data }, HEADER_TOKEN).then((response) => response);
}

function AddCommentsNewsBuilder(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/newsupdates/v2/addcomments/news/builder`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function AddReply(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/comments/reply`;
  return axios.post(url, { data }, HEADER_TOKEN).then((response) => response);
}

function AddNewsBuilderReply(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/newsupdates/v2/replies/news/builder`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function MyNewsDataMobile(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/app/news/my?page=${data.page}&limit=${process.env.REACT_APP_LIMIT}`;
  // if(data.date){
  //   url=`${url}&date=${data.date}`
  // }
  if (data.news_type) {
    url = `${url}&news_type=${data.news_type}`;
  }
  if (data.acknowledged) {
    url = `${url}&is_ack=${data.acknowledged}`;
  }
  if (data.unread) {
    url = `${url}&isseen=${data.unread}`;
  }
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}

function MyNewsData(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/my`;

  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
      params: data,
    })
    .then((response) => response);
}

function getNewsSettings() {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/refreshsettings`;

  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
    })
    .then((response) => response);
}

function saveNewsSettings(body) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/refreshsettings`;

  return axios
    .post(url, body, {
      headers: {
        Authorization: MY_TOKEN,
      },
    })
    .then((response) => response);
}

function AcknowledgedPreview(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/acknowledge`;
  return axios.put(url, { data }, HEADER_TOKEN).then((response) => response);
}

function likeComment(data, id) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/${id}/like`;
  return axios.put(url, { data }, HEADER_TOKEN).then((response) => response);
}

function likeCommentData(data, id) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/comments/${id}/like`;
  return axios.put(url, { data }, HEADER_TOKEN).then((response) => response);
}

function likeCommentReplyData(data, id) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/comments/reply/${id}/like`;
  return axios.put(url, { ...data }, HEADER_TOKEN).then((response) => response);
}

function AddPoll(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/user/poll`;
  return axios.put(url, { data }, HEADER_TOKEN).then((response) => response);
}

function refreshReports() {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/reports/refresh`;

  return axios
    .post(
      url,
      {},
      {
        headers: {
          Authorization: MY_TOKEN,
        },
      }
    )
    .then((response) => response);
}

function fetchLocationBasedReports(data, cancelToken) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/reports/location-report`;

  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
      params: data,
      cancelToken,
    })
    .then((response) => response);
}

function fetchRoleBasedReports(data, cancelToken) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/reports/role-report`;
  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
      params: data,
      cancelToken,
    })
    .then((response) => response);
}

const getNewsReport = (data, cancelToken) => {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/reports/news-report`;

  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
      params: data,
      cancelToken,
    })
    .then((response) => response);
};

const getUserReport = (data, cancelToken) => {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/news/reports/user-report`;

  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
      params: data,
      cancelToken,
    })
    .then((response) => response);
};

export const newsUpdateServices = {
  getNewsReport,
  fetchData,
  fetchAssignUsers,
  addNews,
  editNews,
  deleteNews,
  fetchSeenUsers,
  pushNotification,
  fetchAssignRolesUsers,
  fetchPreviewUsers,
  AddComments,
  AddReply,
  MyNewsData,
  getNewsSettings,
  fetchstatsUsers,
  AcknowledgedPreview,
  likeComment,
  likeCommentData,
  likeCommentReplyData,
  AddPoll,
  newsDashboard,
  SeenNews,
  newsRoleDashboard,
  newsDashboardLocation,
  fetchlocationIdData,
  fetchroleIdData,
  fetchRoles,
  fetchUsers,
  AddCommentsNewsBuilder,
  AddNewsBuilderReply,
  EditComment,
  EditReply,
  fetchRoleBasedReports,
  fetchLocationBasedReports,
  fetchAssignLocationUsers,
  MyNewsDataMobile,
  fetchCompanyUsers,
  getUserReport,
  refreshReports,
  saveNewsSettings,
};
