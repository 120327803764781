import React, { useState, useEffect, useRef } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { dashboardServices } from "../../../../../../eLearning/Dashboard/modules/services";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { history } from "../../../../../../../history";
import { styles } from "../../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../../components/analytics/elearning/ReportTableWrapper";
import axios from "axios";

const LessonLocationBasedRolesReport = ({ classes }) => {
  const { lesson_id, lid } = useParams();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("startDate");
  const endDate = queryParams.get("endDate");
  const location_name = queryParams.get("location_name");
  const lesson_name = queryParams.get("lesson_name");

  const [rolesData, setRolesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("role_name");

  const [checked, setChecked] = useState(true);

  useEffect(() => {
    fetchRolesData(orderBy, order);
  }, [page, startDate, endDate]);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  const fetchRolesData = (sortBy = orderBy, sortOrder = order) => {
    setRolesData([]);
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      lesson_id,
      lid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    dashboardServices
      .getDashboardRoles(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setRolesData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchRolesData(property, isAsc ? "desc" : "asc");
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const handleRowClick = (row) => {
    const newQueryParams = new URLSearchParams(location.search);
    if (startDate) {
      newQueryParams.set("startDate", startDate);
    }
    if (endDate) {
      newQueryParams.set("endDate", endDate);
    }

    newQueryParams.set("location_name", location_name);
    newQueryParams.set("lesson_name", lesson_name);
    newQueryParams.set("role_name", row.role_name);

    history.push({
      pathname: `/analytics/elearning/lesson/${lesson_id}/locations/${lid}/${row._id}`,
      search: newQueryParams.toString(),
    });
  };

  const headCells = [
    { id: "role_name", label: "Role Name" },
    { id: "role_user_count", label: "Users" },
    {
      id: checked ? "inLessonQuizPer" : "completed_question_count",
      label: "In Lesson Quiz",
    },
    {
      id: checked ? "managerQuizPer" : "completed_manager_quiz_count",
      label: "Manager Quiz",
    },
    {
      id: checked ? "videoQuizPer" : "completed_video_quiz_count",
      label: "Video Quiz",
    },
  ];

  const rows = rolesData?.length
    ? rolesData.map((row) => ({
        _id: row._id,
        role_name: row?.role_name,
        "Role Name": row.role_name,
        Users: row.role_user_count,
        "In Lesson Quiz": checked
          ? `${row?.inLessonQuizPer}%`
          : `${row.completed_question_count} / ${row.question_count}`,
        "Manager Quiz": checked
          ? `${row?.managerQuizPer}%`
          : `${row.completed_manager_quiz_count} / ${row.total_manager_quiz_count}`,
        "Video Quiz": checked
          ? `${row?.videoQuizPer}%`
          : `${row.completed_video_quiz_count} / ${row.total_video_quiz_count}`,
      }))
    : [];

  const breadcrumb_items = [
    {
      name: lesson_name,
      link: `/analytics/elearning/lesson/${lesson_id}/locations?lesson_name=${lesson_name}`,
    },
    {
      name: location_name,
      link: "",
    },
  ];

  return (
    <>
      <Grid className={classes.main}>
        <ReportTableWrapper
          classes={classes}
          data={rows}
          headers={headCells}
          loading={loading}
          page={page}
          totalPages={totalPages}
          onPageChange={setPage}
          onRowClick={handleRowClick}
          onSortRequest={handleSortRequest}
          order={order}
          orderBy={orderBy}
          handleCountPercentage={handleCountPercentage}
          checked={checked}
          breadcrumbItems={breadcrumb_items}
        />
      </Grid>
    </>
  );
};

const ConnectWith = connect(null, null)(LessonLocationBasedRolesReport);

export default withStyles(styles)(ConnectWith);
