import React, { useState, useEffect, useRef } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { dashboardServices } from "../../../../../../../eLearning/Dashboard/modules/services";
import { connect } from "react-redux";
import {
  useParams,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { styles } from "../../../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../../../components/analytics/elearning/ReportTableWrapper";
import axios from "axios";

const RoleLocationUserBasedLessonsReport = ({ classes }) => {
  const { lid, rid, uid } = useParams();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("startDate");
  const endDate = queryParams.get("endDate");

  const location_name = queryParams.get("location_name");
  const role_name = queryParams.get("role_name");
  const user_name = queryParams.get("user_name");

  const [lessonsData, setLessonsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("lesson_name");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  useEffect(() => {
    setLessonsData([]);
    fetchLessonsData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchLessonsData = (sortBy = orderBy, sortOrder = order) => {
    setLessonsData([]);
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      lid,
      rid,
      uid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    dashboardServices
      .getDashboardLessons(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setLessonsData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleSortRequest = (property) => {
    setLessonsData([]);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchLessonsData(property, isAsc ? "desc" : "asc");
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const headers = [
    {
      id: "lesson_name",
      label: "Lesson Name",
    },
    {
      id: checked ? "inLessonQuizPer" : "completed_question_count",
      label: "In Lesson Quiz",
    },
    {
      id: "completed_manager_quiz_count",
      label: "Manager Quiz",
    },
    {
      id: "completed_video_quiz_count",
      label: "Video Quiz",
    },
  ];

  const tableData = lessonsData?.length
    ? lessonsData?.map((row) => ({
        "Lesson Name":
          row?.lesson_name?.length > 35
            ? row?.lesson_name?.substring(0, 35) + "..."
            : row?.lesson_name,
        "In Lesson Quiz": checked
          ? `${row?.inLessonQuizPer}%`
          : `${row?.completed_question_count} / ${row?.question_count}`,
        "Manager Quiz": (
          <div
            className={`${
              row.manager_quiz_status === "Pass"
                ? classes.textSuccess
                : row.manager_quiz_status === "Reassigned"
                ? classes.textFail
                : ""
            }`}
          >
            {row?.manager_quiz_status}
          </div>
        ),
        "Video Quiz": (
          <div
            className={`${
              row.video_quiz_status === "Pass"
                ? classes.textSuccess
                : row.video_quiz_status === "Reassigned"
                ? classes.textFail
                : ""
            }`}
          >
            {row?.video_quiz_status}
          </div>
        ),
      }))
    : [];

  const breadcrumb_items = [
    {
      name: role_name,
      link: `/analytics/elearning/role/${rid}/locations?role_name=${role_name}`,
    },
    {
      name: location_name,
      link: `/analytics/elearning/role/${rid}/locations/${lid}?role_name=${role_name}&location_name=${location_name}`,
    },
    {
      name: user_name,
      link: "",
    },
  ];

  return (
    <>
      <Grid className={classes.main}>
        <ReportTableWrapper
          classes={classes}
          data={tableData}
          headers={headers}
          loading={loading}
          page={page}
          totalPages={totalPages}
          onPageChange={setPage}
          onSortRequest={handleSortRequest}
          order={order}
          orderBy={orderBy}
          handleCountPercentage={handleCountPercentage}
          checked={checked}
          breadcrumbItems={breadcrumb_items}
        />
      </Grid>
    </>
  );
};

const ConnectWith = connect(null, null)(RoleLocationUserBasedLessonsReport);

export default withStyles(styles)(ConnectWith);
