import {
  // MoNGO_DB_API_URL,
  HEADER_TOKEN,
  MY_TOKEN,
} from "../../../../config";
import axios from "axios";

export const dashboardServices = {
  fetchLocationBased,
  fetchRoleBased,
  fetchLessonBased,
  fetchUserBased,
  fetchLocationLessondata,
  fetchLocationNamedata,
  fetchLocationLessonCompleteddata,
  fetchLocationUser,
  fetchRoleLocationUser,
  fetchRoleNameData,
  fetchRoleCompletedLessonData,
  fetchRoleLesson,
  fetchRoleUser,
  fetchlessonLocation,
  fetchlessonlocationNameUser,
  fetchlessonUser,
  fetchUserLocation,
  fetchUserLesson,
  fetchRoleExport,
  fetchLocationExport,
  fetchUserExport,
  fetchLessonExport,
  fetchUserAssignedLessons,
  fetchUserLessonCategories,
  fetchUserLessonsByCategory,

  getDashboardUsers,
  getDashboardLessons,
  getDashboardRoles,
  getDashboardLocations,
  refreshReports,
};

function fetchLocationBased(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/location-based`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
      }
    )
    .then((response) => response);
}

function fetchRoleBased(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/role-based`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
      }
    )
    .then((response) => response);
}

function fetchLessonBased(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/lessons-based`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
      }
    )
    .then((response) => response);
}

function fetchUserBased(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/user-based`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
      }
    )
    .then((response) => response);
}

function refreshReports(cancelToken) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/refresh`;

  return axios
    .post(
      url,
      {},
      {
        headers: {
          Authorization: MY_TOKEN,
        },
        cancelToken,
      }
    )
    .then((response) => response);
}

function fetchLocationLessondata(id, data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/location-based/lessons/${id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchLocationNamedata(value) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/location-based/${value.id}?page=${value.pageNumber}&limit=${value.nPerPage}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchLocationLessonCompleteddata(id, data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/location-based/lessons/completed/new/${id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchLocationUser(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/location-based/users/${data.id}?page=${data.pageNumber}&limit=${data.nPerPage}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchRoleLocationUser(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/role-based/locations/${data.id}?page=${data.pageNumber}&limit=${data.nPerPage}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchRoleUser(value) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/role-based/users/${value.id}?page=${value.pageNumber}&limit=${value.nPerPage}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchRoleLesson(id, data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/role-based/lessons/${id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchRoleNameData(value) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/role-based/${value.id}?page=${value.pageNumber}&limit=${value.nPerPage}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchRoleCompletedLessonData(id, data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/role-based/lessons/completed/new/${id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchlessonLocation(id, data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/lessons-based/locations/new/${id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchlessonUser(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/lessons-based/users/new/${data.id}?page=${data.pageNumber}&limit=${data.nPerPage}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchlessonlocationNameUser(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/lessons-based/lessons`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchUserLocation(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/user-based/users/${data.id}?page=${data.pageNumber}&limit=${data.nPerPage}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchUserLesson(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/user-based/lessons/${data.id}?page=${data.pageNumber}&limit=${data.nPerPage}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchRoleExport(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/role-based/export`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchLocationExport() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/location-based/export`,

      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchUserExport() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/user-based/export`,

      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchLessonExport() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/lessons-based/export`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchUserAssignedLessons(user_id, data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/users/${user_id}/lessons`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
      }
    )
    .then((response) => response);
}

function fetchUserLessonCategories(user_id, data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/user-based/${user_id}/categories`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
      }
    )
    .then((response) => response);
}

function fetchUserLessonsByCategory(user_id, ecid, data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/user-based/${user_id}/categories/${ecid}`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
      }
    )
    .then((response) => response);
}

// NEW DASHBOARD APIS

function getDashboardUsers(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/users-reports`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function getDashboardLessons(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/lesson-reports`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function getDashboardRoles(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/roles-reports`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function getDashboardLocations(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/dashboard/locations-reports`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}
