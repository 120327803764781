import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import LessonBasedLocationsReport from "./LessonBasedLocationsReport";
import LessonBasedRolesReport from "./LessonBasedRolesReport";
import LessonBasedUsersReport from "./LessonBasedUsersReport";
import { useParams, useLocation } from "react-router-dom";
import { history } from "../../../../../../history";
import { styles } from "../../../../ReportStyles";
import Breadcrumb from "../../../../../../components/analytics/Breadcrumb";

const LessonBasedDataReports = ({ classes }) => {
  const { lesson_id, tab } = useParams();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("startDate");
  const endDate = queryParams.get("endDate");

  const lesson_name = queryParams.get("lesson_name");

  const [activeIndex, setActiveIndex] = useState(1);

  useEffect(() => {
    if (tab === "users") {
      setActiveIndex(3);
    } else if (tab === "roles") {
      setActiveIndex(2);
    } else {
      setActiveIndex(1);
    }
  }, [tab]);

  const handleTabChange = (label) => {
    setActiveIndex(label.status);

    const newQueryParams = new URLSearchParams(location.search);
    if (startDate) {
      newQueryParams.set("startDate", startDate);
    }
    if (endDate) {
      newQueryParams.set("endDate", endDate);
    }
    if (lesson_name) {
      newQueryParams.set("lesson_name", lesson_name);
    }

    history.push({
      pathname: `/analytics/elearning/lesson/${lesson_id}/${label.title}`,
      search: newQueryParams.toString(),
    });
  };

  const buttonsList = [
    { status: 1, title: "locations" },
    { status: 2, title: "roles" },
    { status: 3, title: "users" },
  ];

  const breadcrumb_items = [{ name: lesson_name, link: "" }];

  return (
    <Grid className={classes.main}>
      <Breadcrumb
        items={breadcrumb_items}
        backUrl={"/analytics/elearning/lesson"}
      />
      <Grid
        style={{ margin: "auto", paddingTop: "30px", paddingBottom: "30px" }}
        item
        xl={12}
        md={12}
        lg={12}
        sm={12}
        xs={12}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.tabs}
        >
          {buttonsList.map((label, index) => {
            const isFirstButton = index === 0;
            const isLastButton = index === buttonsList.length - 1;

            return (
              <>
                <Grid>
                  <Button
                    style={{
                      borderRadius: isFirstButton
                        ? "8px 0 0 8px"
                        : isLastButton
                        ? "0 8px 8px 0"
                        : 0,
                      color: activeIndex === label.status ? "white" : "black",
                      backgroundColor:
                        activeIndex === label.status ? "black" : "white",
                    }}
                    className={classes.tabButton}
                    onClick={() => handleTabChange(label)}
                  >
                    {label.title}
                  </Button>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Grid>

      {activeIndex === 1 && (
        <LessonBasedLocationsReport
          lesson_id={lesson_id}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {activeIndex === 2 && (
        <LessonBasedRolesReport
          lesson_id={lesson_id}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {activeIndex === 3 && (
        <LessonBasedUsersReport
          lesson_id={lesson_id}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </Grid>
  );
};

export default withStyles(styles)(LessonBasedDataReports);
