import React from "react";
import { useHistory } from "react-router-dom";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "./Breadcrumb.css";

const Breadcrumb = ({ items, backUrl }) => {
  const history = useHistory();

  const lastClickableItem = items
    .slice()
    .reverse()
    .find((item) => item.link);

  return (
    <div className="breadcrumb-container">
      <IoIosArrowBack
        className="analytics-breadcrumb-back-icon"
        onClick={() => {
          if (backUrl) {
            history.push(backUrl);
          } else if (lastClickableItem) {
            history.push(lastClickableItem.link);
          }
        }}
      />

      {items.map((item, index) => (
        <div
          key={index}
          className={`breadcrumb-item-link`}
          onClick={() => {
            if (item.link) history.push(item.link);
          }}
        >
          {index !== items.length - 1 ? (
            <>
              {item.name}
              <IoIosArrowForward className="arrow-span" />
            </>
          ) : (
            item.name
          )}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumb;
