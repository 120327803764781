import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { Routes } from "./routes";
import NoPermission from "./components/NoPermission";

const AllRoutes = () => {
  const permissionsData = useSelector(
    (state) => state.settingsRoles.permissionsData
  );

  return (
    <Switch>
      {Routes.map((prop, key) => {
        const ComponentToRender =
          !prop.permission ||
          (permissionsData && permissionsData[prop.permission])
            ? prop.component
            : () => (
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <NoPermission />
                  </Grid>
                </Grid>
              );

        return (
          <Route
            exact
            path={prop.path}
            key={key}
            render={(routeProps) => (
              <prop.access
                {...routeProps}
                layout={prop.layout}
                component={ComponentToRender}
              />
            )}
          />
        );
      })}
      <Redirect from="*" to="/404" />
    </Switch>
  );
};

export default AllRoutes;
