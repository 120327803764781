import React, { useState, useEffect, useRef } from "react";
import { withStyles, Tooltip, Typography } from "@material-ui/core";
import { dashboardServices } from "../../../../../eLearning/Dashboard/modules/services";
import { connect } from "react-redux";
import { styles } from "../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../components/analytics/elearning/ReportTableWrapper";
import axios from "axios";

const LessonBasedUsersReport = ({ classes, lesson_id, startDate, endDate }) => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("user_name");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  useEffect(() => {
    fetchUsersData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchUsersData = (sortBy = orderBy, sortOrder = order) => {
    setUsersData([]);
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      lesson_id,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    dashboardServices
      .getDashboardUsers(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setUsersData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchUsersData(property, isAsc ? "desc" : "asc");
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const headers = [
    { id: "user_name", label: "User Name" },
    { id: "user_role", label: "Role" },
    {
      id: checked ? "inLessonQuizPer" : "completed_question_count",
      label: "In Lesson Quiz",
    },
    { id: "completed_manager_quiz_count", label: "Manager Quiz" },
    { id: "completed_video_quiz_count", label: "Video Quiz" },
  ];

  const data = usersData?.length
    ? usersData?.map((row) => ({
        _id: row._id,
        "User Name":
          (
            <Tooltip
              arrow
              title={
                <div className={classes.tooltipContainer}>
                  <Typography className={classes.tooltipTextStyle}>
                    {row?.contact}
                  </Typography>
                  <Typography className={classes.tooltipTextStyle}>
                    {row?.email}
                  </Typography>
                </div>
              }
            >
              <Typography className={classes.categoryName}>
                {row.user_name}
              </Typography>
            </Tooltip>
          ) || "N/A",
        Role: row?.user_role || "N/A",
        "In Lesson Quiz": checked
          ? `${row?.inLessonQuizPer}%`
          : `${row?.completed_question_count} / ${row?.question_count}`,
        "Manager Quiz": row?.manager_quiz_status,
        "Video Quiz": row?.video_quiz_status,
      }))
    : [];

  return (
    <>
      <ReportTableWrapper
        classes={classes}
        data={data}
        headers={headers}
        loading={loading}
        page={page}
        totalPages={totalPages}
        onPageChange={setPage}
        onRowClick={() => {}}
        onSortRequest={handleSortRequest}
        order={order}
        orderBy={orderBy}
        handleCountPercentage={handleCountPercentage}
        checked={checked}
      />
    </>
  );
};

const ConnectWith = connect(null, null)(LessonBasedUsersReport);

export default withStyles(styles)(ConnectWith);
