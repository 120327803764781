import React, { useState, useEffect, useRef } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { history } from "../../../../../../../history";
import { styles } from "../../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../../components/analytics/elearning/ReportTableWrapper";
import { newsUpdateServices } from "../../../../../../News/NewsUpdate/modules/services";
import axios from "axios";

const NewsLocationBasedRolesReport = ({ classes }) => {
  const { news_id, lid } = useParams();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("startDate");
  const endDate = queryParams.get("endDate");
  const location_name = queryParams.get("location_name");
  const news_name = queryParams.get("news_name");

  const [rolesData, setRolesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("role_name");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  useEffect(() => {
    setRolesData([]);
    fetchRolesData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchRolesData = (sortBy = orderBy, sortOrder = order) => {
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      news: news_id,
      lid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    newsUpdateServices
      .fetchRoleBasedReports(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setRolesData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleSortRequest = (property) => {
    setRolesData([]);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchRolesData(property, isAsc ? "desc" : "asc");
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const handleRowClick = (row) => {
    const newQueryParams = new URLSearchParams(location.search);
    if (startDate) {
      newQueryParams.set("startDate", startDate);
    }
    if (endDate) {
      newQueryParams.set("endDate", endDate);
    }

    newQueryParams.set("location_name", location_name);
    newQueryParams.set("news_name", news_name);
    newQueryParams.set("role_name", row.role_name);

    history.push({
      pathname: `/analytics/news/news/${news_id}/locations/${lid}/${row._id}`,
      search: newQueryParams.toString(),
    });
  };

  const headCells = [
    { id: "role_name", label: "Role Name" },
    { id: "total_news", label: "Users" },
    {
      id: checked ? "news_seen_percentage" : "news_seen",
      label: "Seen",
    },
    { id: checked ? "news_ack_percentage" : "news_ack", label: "Acknowledged" },
  ];

  const rows = rolesData.map((row) => ({
    _id: row._id,
    role_name: row?.role_name,
    "Role Name": row.role_name,
    Users: row.assign,
    Seen: checked
      ? `${row.news_seen_percentage}%`
      : `${row.news_seen} / ${row.assign}`,
    Acknowledged: checked
      ? `${row.news_ack_percentage}%`
      : `${row.news_ack} / ${row.assign}`,
  }));

  const breadcrumb_items = [
    {
      name: news_name,
      link: `/analytics/news/news/${news_id}/locations?news_name=${news_name}`,
    },
    {
      name: location_name,
      link: "",
    },
  ];

  return (
    <>
      <Grid className={classes.main}>
        <ReportTableWrapper
          classes={classes}
          data={rows}
          headers={headCells}
          loading={loading}
          page={page}
          totalPages={totalPages}
          onPageChange={setPage}
          onRowClick={handleRowClick}
          onSortRequest={handleSortRequest}
          order={order}
          orderBy={orderBy}
          handleCountPercentage={handleCountPercentage}
          checked={checked}
          breadcrumbItems={breadcrumb_items}
        />
      </Grid>
    </>
  );
};

const ConnectWith = connect(null, null)(NewsLocationBasedRolesReport);

export default withStyles(styles)(ConnectWith);
