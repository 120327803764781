import { analyticsColor } from "../../config";

export const styles = (theme) => ({
  main: {
    backgroundColor: "white",
    padding: "15px 20px",
    borderRadius: "10px",
    height: "100%",
  },
  title: {
    fontWeight: 600,
    fontSize: "24px",
  },
  right: {
    textAlign: "right",
  },
  center: {
    textAlign: "center",
  },
  downloadBtn: {
    backgroundColor: "black",
    color: "white",
    textTransform: "capitalize",
    padding: "5px 20px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  tabs: {
    marginTop: "20px",
  },
  myTeamTabs: {
    border: "1px solid black",
    borderRadius: "25px",
    display: "flex",
  },
  myTeamTabButton: {
    display: "block",
    border: "1px solid #00000059",
    textTransform: "capitalize",
    textDecoration: "none",
    padding: "8px",
    fontWeight: 500,
    fontSize: "14px",
    borderRadius: 0,
    width: "150px",
    textAlign: "center",
    color: "white",
    backgroundColor: "black",
    borderRadius: "25px",
    "&:hover": {
      textDecoration: "none",
      color: "white",
    },
  },
  borderRadiusNone: {
    border: "0px !important",
  },
  inactive: {
    backgroundColor: "white !important",
    color: "black !important",
  },
  tabButton: {
    display: "block",
    border: "1px solid #00000059",
    textTransform: "capitalize",
    padding: "8px",
    fontWeight: 500,
    fontSize: "14px",
    borderRadius: 0,
    width: "150px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#fcfcfc",
      textDecoration: "none",
    },
  },
  tableHeaderLeft: {
    display: "flex",
    alignItems: "center",
  },
  tableHeaderRight: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "10px",
  },
  tableHeading: {
    fontWeight: "bold",
    padding: "0px 10px",
    margin: "0px",
    textTransform: "capitalize",
  },
  switchView: {
    display: "flex",
    alignItems: "center",
    marginLeft: "15px",
  },
  switchViewLabel: {
    margin: 0,
    marginLeft: "5px",
    fontSize: "14px",
  },
  tabFilter: { margin: 0 },
  dateFilter: {
    background: "#f2f2f2",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "5px",
    padding: "5px 10px",
    width: "150px",
  },
  dateFilterText: {
    margin: 0,
    fontSize: "14px",
  },
  visibilityBox: {
    backgroundColor: "#fae3dc",
    borderRadius: "5px",
    padding: "5px 10px",
    cursor: "pointer",
    // marginRight: "15px",
  },
  visibilityIcon: {
    color: "#ec6845",
  },
  tableView: {
    marginTop: "25px",
    border: "1px solid #00000059",
    borderRadius: "5px",
    padding: "15px",
  },
  table: {
    marginTop: "10px",
    width: "100%",
  },
  trHead: {
    borderBottom: "1px solid #00000033",
  },
  tr: {
    borderBottom: "1px solid #00000033",
    "&:last-child": {
      borderBottom: "0px",
    },
  },
  th: {
    fontSize: "14px",
    padding: "10px",
    textTransform: "capitalize",
  },
  td: {
    fontSize: "14px",
    color: "#000000CC",
    padding: "10px",
  },
  upDownIcon: {
    height: "15px",
  },
  moreResultsDiv: {
    position: "relative",
  },
  moreResultsLine: {
    backgroundColor: "#dadce0",
    border: "0",
    height: "1px",
    left: "0",
    marginTop: "18px",
    position: "absolute",
    width: "100%",
  },
  moreResultsBtn: {
    marginTop: "20px",
    background: "#f1f3f4",
    border: "1px solid rgba(255, 255, 255, 0)",
    boxSizing: "borderBox",
    cursor: "pointer",
    fontSize: "14px",
    lineHeight: "20px",
    pointerEvents: "auto",
    position: "relative",
    width: "300px",
    padding: "7px 11px",
    margin: "0",
    flexDirection: "rowReverse",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    height: "36px",
    borderRadius: "18px",
  },
  // search
  textField: {
    background: "white",
    "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: "5px",
    },
    ".MuiOutlinedInput-adornedEnd": {
      background: "white",
    },
  },
  // refresh
  reportsLoadingText: {
    margin: 0,
    marginLeft: "auto",
    textAlign: "right",
  },
  refreshBtn: {
    padding: "5px 8px",
    borderRadius: "5px",
    border: "none",
    height: "40px",
    width: "40px",
    // marginLeft: "10px",
  },
  refreshIcon: {
    color: analyticsColor.main,
  },
  tooltipContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
  tooltipTextStyle: {
    fontSize: "12px",
    color: "white",
  },
  textSuccess: { color: "green" },
  textFail: { color: "red" },
});
